// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./wgdvbyQBP-0.js";

const cycleOrder = ["a4SxB4QiA", "LFyi9Ppjf"];

const serializationHash = "framer-gxrjy"

const variantClassNames = {a4SxB4QiA: "framer-v-lpx1ou", LFyi9Ppjf: "framer-v-1q9u8o1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {EcHMMQmqc: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Close: "LFyi9Ppjf", Menu: "a4SxB4QiA"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "a4SxB4QiA", XKaY3MrGu: tap ?? props.XKaY3MrGu} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, XKaY3MrGu, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "a4SxB4QiA", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap16y2uw0 = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (XKaY3MrGu) {
const res = await XKaY3MrGu(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-lpx1ou", className, classNames)} data-framer-name={"Menu"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"a4SxB4QiA"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTap16y2uw0} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({LFyi9Ppjf: {"data-framer-name": "Close"}}, baseVariant, gestureVariant)}><motion.div className={"framer-1ysodbr"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"lkB4LJqNo"} style={{backgroundColor: "rgb(102, 102, 102)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{LFyi9Ppjf: {rotate: -45}}}/><motion.div className={"framer-pwiv6"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"cm94iHyis"} style={{backgroundColor: "rgb(102, 102, 102)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, rotate: 0}} variants={{LFyi9Ppjf: {rotate: 45}}}/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-gxrjy[data-border=\"true\"]::after, .framer-gxrjy [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-gxrjy.framer-oe463x, .framer-gxrjy .framer-oe463x { display: block; }", ".framer-gxrjy.framer-lpx1ou { cursor: pointer; height: 25px; overflow: hidden; position: relative; width: 25px; }", ".framer-gxrjy .framer-1ysodbr { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(62.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-gxrjy .framer-pwiv6 { flex: none; height: 2px; left: calc(50.00000000000002% - 20px / 2); overflow: hidden; position: absolute; top: calc(37.50000000000002% - 2px / 2); width: 20px; will-change: var(--framer-will-change-override, transform); }", ".framer-gxrjy.framer-v-1q9u8o1.framer-lpx1ou { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 25px); }", ".framer-gxrjy.framer-v-1q9u8o1 .framer-1ysodbr, .framer-gxrjy.framer-v-1q9u8o1 .framer-pwiv6 { top: calc(50.00000000000002% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 25
 * @framerIntrinsicWidth 25
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"LFyi9Ppjf":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"XKaY3MrGu":"tap"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerwgdvbyQBP: React.ComponentType<Props> = withCSS(Component, css, "framer-gxrjy") as typeof Component;
export default FramerwgdvbyQBP;

FramerwgdvbyQBP.displayName = "Hamburger";

FramerwgdvbyQBP.defaultProps = {height: 25, width: 25};

addPropertyControls(FramerwgdvbyQBP, {variant: {options: ["a4SxB4QiA", "LFyi9Ppjf"], optionTitles: ["Menu", "Close"], title: "Variant", type: ControlType.Enum}, XKaY3MrGu: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerwgdvbyQBP, [])